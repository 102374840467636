const LogoJewelrymine = () => {
    return (
        <svg width="766" height="800" viewBox="0 0 766 800" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g className="logo-jewelry-i">
                <path
                    d="M106.952 78.3261C128.426 78.3261 145.842 60.7924 145.842 39.1633C145.842 17.5341 128.426 0 106.952 0C85.4782 0 68.0758 17.5341 68.0758 39.1633C68.0758 60.7924 85.4782 78.3261 106.952 78.3261Z"
                    fill="#F4F4F4"
                />
                <path
                    d="M18.3638 142.17C47.1888 142.17 76.0845 171.208 76.0845 200.247V535.28C76.0845 649.113 76.0845 800 0 800C134.653 800 136.265 675.691 136.265 540.131V200.247C136.265 171.208 165.09 142.17 194 142.17H18.3638Z"
                    fill="#F4F4F4"
                />
            </g>
            <path
                className="logo-jewelry-m"
                d="M684.736 0.140668C754.199 6.96017 725.606 95.9664 706.548 141.383L498.871 636.12C487.756 608.7 477.465 579.594 467.943 550.558C467.943 550.558 467.943 550.558 328.751 141.383C303.471 67.0002 326.052 0 387.853 0C291.81 0 269.019 83.5228 269.019 142.508V640.971C269.229 647.016 269.299 653.555 269.019 660.586V741.858C269.019 770.894 240.51 800 212 800H269.019V799.93C271.438 799.93 282.148 800 284.833 800H341.866C313.356 800 284.833 770.964 284.833 741.858V204.025L310.238 278.549L358.714 420.846L399.864 541.63C456.897 717.603 506.883 799.93 632.051 799.93C764.42 799.93 766 674.858 766 540.082V142.016C766 96.3879 757.233 6.81964 684.736 0.140668ZM706.548 535.162C706.548 648.985 706.548 783.761 632.051 783.761C578.961 783.761 540.091 729.695 507.638 656.226L706.548 182.441V535.162Z"
                fill="#F4F4F4"
            />
        </svg>
    );
};

export default LogoJewelrymine;
