import { useCursorTypeState } from '@/atoms/cursor';
import { useIsAppNavOpenedValue } from '@/atoms/opened-popups';
import classNames from 'classnames';
import Link from '@/components/shared/Link';
import Nav from '../Nav';
import LogoRotormine from './LogoRotormine';
import LogoJewelrymine from './LogoJewelrymine';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { CATEGORIES_IDS } from '@/api/categories';

const Header = () => {
    const headerLogoRotor = useRef<HTMLAnchorElement>(null);
    const headerLogoJewerly = useRef<HTMLAnchorElement>(null);
    const [, setCursorType] = useCursorTypeState();
    const isAppNavPopupOpened = useIsAppNavOpenedValue();
    const router = useRouter();

    useEffect(() => {
        const { current } = headerLogoRotor;

        const onHeaderLogoRotorClick = () => {
            if (current && matchMedia('(hover: none)').matches) {
                current.classList.add('header-logo-rotor--animation');
                current.addEventListener(
                    'animationend',
                    () => {
                        current.classList.remove('header-logo-rotor--animation');
                    },
                    { once: true },
                );
            }
        };

        current?.addEventListener('click', onHeaderLogoRotorClick);

        return () => {
            current?.removeEventListener('click', onHeaderLogoRotorClick);
        };
    }, []);

    useEffect(() => {
        const { current } = headerLogoJewerly;

        const onHeaderLogoJewerlyClick = () => {
            if (current && matchMedia('(hover: none)').matches) {
                current.classList.add('header-logo-jewerly--animation');
                current.addEventListener(
                    'animationend',
                    () => {
                        current.classList.remove('header-logo-jewerly--animation');
                    },
                    { once: true },
                );
            }
        };

        current?.addEventListener('click', onHeaderLogoJewerlyClick);

        return () => {
            current?.removeEventListener('click', onHeaderLogoJewerlyClick);
        };
    }, []);

    return (
        <header
            className={classNames('header wrapper', {
                'burger-opened': isAppNavPopupOpened,
            })}
        >
            <div className="header-inner">
                <div className="header-logos-wrapper">
                    <div className="header-logos">
                        <Link
                            ref={headerLogoRotor}
                            href="/rotormine"
                            className={classNames('header-logo header-logo-rotor', {
                                'header-logo-rotor--infinite':
                                    router.asPath === '/rotormine' ||
                                    router.query.category === CATEGORIES_IDS.watch.toString(),
                            })}
                            onPointerOver={() => {
                                setCursorType('logo-rotor');
                            }}
                            onPointerOut={() => setCursorType('default')}
                            aria-label="Rotormine"
                        >
                            <LogoRotormine />
                        </Link>
                        <Link
                            ref={headerLogoJewerly}
                            href="/jewelrymine"
                            className={classNames('header-logo header-logo-jewerly', {
                                'header-logo-jewerly--infinite':
                                    router.asPath === '/jewelrymine' ||
                                    router.query.category === CATEGORIES_IDS.decoration.toString(),
                            })}
                            onPointerOver={() => {
                                setCursorType('logo-jewerly');
                            }}
                            onPointerOut={() => setCursorType('default')}
                            aria-label="Jewelrymine"
                        >
                            <LogoJewelrymine />
                        </Link>
                    </div>
                </div>
                <Nav />
            </div>
        </header>
    );
};

export default Header;
